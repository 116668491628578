import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { openDialogGlobal, closeDialogGlobal } from 'src/actions';

import PropTypes from 'prop-types';
import {
    makeStyles,
    Typography,
    Box,
    Container,
    IconButton,
    Grid,
    List,
    ListItem,
    Link,
    ListItemText,
} from '@material-ui/core';

import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import PhoneIcon from '@material-ui/icons/Phone';
import clsx from 'clsx';

import {
    Instagram as InstaIcon,
    Facebook as FaceIcon,
    Twitter as TwitterIcon,
    Youtube as YoutubeIcon
} from 'react-feather';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import imageCertified from 'src/assets/SSL_Certificado.png';
import opiniao from 'src/theme/scss/images/opiniao.png';
import reclameaqui from 'src/theme/scss/images/reclameAquiFooter.png';
import exame from 'src/theme/scss/images/exame.png';
import visa from 'src/theme/scss/icons/visa.svg';
import master from 'src/theme/scss/icons/master.svg';
import diners from 'src/theme/scss/icons/diners.svg';
import hiper from 'src/theme/scss/icons/hiper.svg';
import hipercard from 'src/theme/scss/icons/hipercard.svg';
import amex from 'src/theme/scss/icons/amex.svg';
import elo from 'src/theme/scss/icons/elo.svg';
import boleto from 'src/theme/scss/icons/boleto.svg';
import logo from 'src/theme/scss/icons/logoNewBlack.svg';
import ig from 'src/theme/scss/icons/ig.svg';
import fb from 'src/theme/scss/icons/fb.svg';
import yt from 'src/theme/scss/icons/yt.svg';
import li from 'src/theme/scss/icons/li.svg';
import tt from 'src/theme/scss/icons/tt.svg';
import news from 'src/theme/scss/icons/news.svg';
import top from 'src/theme/scss/icons/top.svg';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import pagamentoBoletoIcon from 'src/theme/scss/icons/pagamento-boleto.svg';
import pagamentoCartaoIcon from 'src/theme/scss/icons/pagamento-cartao.svg';
import pagamentoNubankIcon from 'src/theme/scss/icons/pagamento-nubank.svg';
import pagamentoPagseguroIcon from 'src/theme/scss/icons/pagamento-pagseguro.svg';
import pagamentoPixIcon from 'src/theme/scss/icons/pagamento-pix.svg';
import pagamentoSantanderIcon from 'src/theme/scss/icons/pagamento-santander.svg';
import pagamentoAmeIcon from 'src/theme/scss/icons/pagamento-ame.svg';
import { useHistory } from "react-router-dom";
import DialogTelefoneSuporte from '../../components/DialogTelefoneSuporte';
import DialogVendas from '../../components/DialogVendas';
import Newsletter from 'src/components/Newsletter';
import { EmutuaFooter } from 'src/components/EmutuaFooter';

import { ComparisonDialog } from '../../components/ComparisonDialog';

import { PhoneInTalk as PhoneInTalkIcon } from '@material-ui/icons';
import { HeadsetMic as HeadsetMicIcon } from '@material-ui/icons';

import centralDeVendasIcon from 'src/theme/scss/images/central-de-vendas.svg';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        borderTop: '1px solid #e8e8e8',
        backgroundColor: '#f4f4f4',
        [theme.breakpoints.down('xs')]: {
            border: 0
        }
    },
    btnHome: {
        [theme.breakpoints.down('xs')]: {
            width: '100% !important'
        }
    },
    title: {
        fontWeight: 800,
        textTransform: 'uppercase',
        marginBottom: 10
    },
    link: {
        paddingLeft: 0,
        paddingRight: 0,
        ...theme?.components?.footer?.link
    },
    linkIcon: {
        ...theme?.components?.footer?.linkIcon,
        margin: 0,
        padding: 10
    },
    text: {
        color: '#C9C9C9',
        textAlign: 'center',
        display: 'block'
    },
    newsletterRoot: {
        ...theme?.components?.footer?.newsletter?.root
    },

    socialIcon: {
        padding: theme.spacing(1),
    },
    logo: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        maxHeight: 40,
    },
    floatingBar: {
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        right: '25px',
        bottom: '25%',
        zIndex: 10,
        padding: '5px',
        borderRadius: '10px',
        [theme.breakpoints.down('xs')]: {
            right: theme.spacing(1),
        }
    },
    floatingBarIcon: {
        margin: '4px 0',
        padding: '5px',
    },
    floatingBarIconFacebook: {
        color: '#FFFFFF',
        backgroundColor: '#38529a',
        "&:hover": {
            backgroundColor: "#38529a"
        }
    },
    floatingBarIconInstagram: {
        color: '#FFFFFF',
        backgroundColor: '#e5518b',
        "&:hover": {
            backgroundColor: "#e5518b"
        }
    },
    floatingBarIconYoutube: {
        color: '#FFFFFF',
        backgroundColor: '#e63f36',
        "&:hover": {
            backgroundColor: "#e63f36"
        }
    },
    floatingBarIconWhatsapp: {
        color: '#FFFFFF',
        backgroundColor: '#76B900',
        height: '48px',
        width: '48px',
        transition: 'transform .3s',
        "&:hover": {
            color: '#FFFFFF',
            backgroundColor: "#76B900",
            transform: 'scale(1.125)'
        },
        "& svg": {
            height: '32px',
            width: '32px',
        }
    },
    floatingBarIconVendas: {
        color: '#FFFFFF',
        backgroundColor: '#db1842',
        height: '48px',
        width: '48px',
        transition: 'transform .3s',
        "&:hover": {
            color: '#FFFFFF',
            backgroundColor: "#db1842",
            transform: 'scale(1.125)'
        },
        "& svg": {
            height: '32px',
            width: '32px',
        }
    },
    buttonOk: {
        paddingTop: '15px',
        paddingBottom: '15px',
    },
    list: {
        paddingBottom: 40,
        [theme.breakpoints.down('xs')]: {
            padding: 0
        }
    },
    hiddenMobile: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    listItem: {
        fontFamily: 'Manrope',
        fontSize: 14,
        color: '#777777',
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    textItem: {
        margin: '0 0 5px 0',
        whiteSpace: 'nowrap'
    },
    paymentForms: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        justifyItems: 'center',
        gap: '16px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '270px',
            margin: '0 auto',
            borderTop: '1px solid #E4E4E4',
            borderBottom: '1px solid #E4E4E4',
            padding: '25px 0'
        }
    },
    paymentForm: {
        '& img': {
            width: '30px',
            height: '30px',
            [theme.breakpoints.down('sm')]: {
                width: '20px',
                height: '20px',
            }
        },
    },
    securityItem: {
        marginBottom: 20
    },
    infoDefault: {
        borderTop: '2px solid #ececec',
        [theme.breakpoints.down('xs')]: {
            border: 0,
            padding: '0 60px 40px',
            textAlign: 'center'
        }
    },
    logo: {
        maxWidth: 134,
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            margin: '0 auto 10px'
        }
    },
    textInfo: {
        display: 'block',
        color: '#777777',
        marginBottom: 15
    },
    socialMedia: {
        textAlign: 'center',
        position: 'relative',
        paddingTop: 25,
        '&:before': {
            content: '""',
            display: 'block',
            height: 1,
            backgroundColor: '#e4e4e4',
            position: 'absolute',
            top: 0,
            right: 50,
            left: 50

        }
    },
    newsletter: {
        padding: '70px 0',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            border: 0,
            padding: '32px 0',
        }
    },
    bigTitleNewsletter: {
        color: '#FFFFFF',
        textAlign: 'center',
        marginBottom: '-35px',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    textNewsletter: {
        color: '#777777',
        marginBottom: '30px',
    },
    controlNewsletter: {
        width: '100%'
    },
    inputNewsletter: {
        color: '#777777',
        width: '100%'
    },
    textItemBold: {
        color: '#333333'
    },
    hiddenDesktop: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        },
    },
    whatssAppBtn: {
        position: 'absolute',
    }
}));

function Footer({ className, ...rest }) {

    const dispatch = useDispatch();
    const dialogGlobal = useSelector((state) => state.interface.dialogGlobal);

    let history = useHistory();

    const classes = useStyles();

    const store = useSelector((state) => state.interface.store);

    const theme = useTheme();
    const mobileSize = !(useMediaQuery(theme.breakpoints.up('sm')));

    const handleOpenChatVendedor = (e) => {
        return window.open('https://chat.movidesk.com/ChatWidgetNewNew/Landing/185B5AAE5596487A9FD4838043E84163', 'Chat', 'location=yes,height=570,width=520,top=50,location=no,status=yes,scrollbars=yes,resizable=yes')
    }

    const scrollToTop = () => {
        const pageContent = document.getElementById('page-content');
        pageContent.scrollTo({ top: 0, behavior: 'smooth' });
    }

    let linkWhatsapp = false;
    if (store.contacts.whatsapp) {
        if (mobileSize) {
            linkWhatsapp = 'https://wa.me/' + store.contacts.whatsapp.match(/\d+/g).join([]);
        }
        else {
            linkWhatsapp = 'https://api.whatsapp.com/send?phone=' + store.contacts.whatsapp.match(/\d+/g).join([]);
        }
    }



    return (
        <>
            <Box className={classes.newsletter}>
                <Newsletter />
            </Box>
            <Box pt={3} pb={3} className={clsx(classes.root + ' footer-default')}>
                <Container maxWidth={false}>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item md={3} xs={12} >
                            <Grid container spacing={2}>
                                <Grid item md={5} xs={12}>
                                    <Box>
                                        <img src={logo} className={classes.logo + ' img-fluid'} />
                                    </Box>
                                </Grid>
                                <Grid item md={7} xs={12} className={classes.hiddenMobile}>
                                    <Typography variant="caption" className={classes.textInfo}>
                                        <strong className={classes.textItemBold}>Avell Notebooks de Alto desempenho</strong><br />
                                        CNPJ: 19.117.785/0001-05
                                    </Typography>
                                    <Typography variant="caption" className={classes.textInfo}>
                                        Rua Matrinxã, 687, Edifício 3 - Parte 1
                                        Distrito Industrial - Manaus - AM<br />
                                        CEP: 69.075-150
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item md={12} xs={12}>
                                    <Box className={classes.socialMedia}>
                                        {store.social.instagram && (
                                            <IconButton className={classes.linkIcon} component={Link} href={store.social.instagram} target="blank">
                                                <img src={ig} />
                                            </IconButton>
                                        )}

                                        {store.social.facebook && (
                                            <IconButton className={classes.linkIcon} component={Link} href={store.social.facebook} target="blank">
                                                <img src={fb} />
                                            </IconButton>
                                        )}

                                        {/* {store.social.youtube && ( */}
                                        <IconButton className={classes.linkIcon} component={Link} href={store.social.youtube} target="blank">
                                            <img src={yt} />
                                        </IconButton>
                                        {/* )} */}

                                        <IconButton className={classes.linkIcon} component={Link} href={store.social.linkedin} target="blank">
                                            <img src={li} />
                                        </IconButton>

                                        <IconButton className={classes.linkIcon} component={Link} href={store.social.twitter} target="blank">
                                            <img src={tt} />
                                        </IconButton>

                                        {/* <IconButton className={classes.linkIcon} component={Link} href={store.social.news} target="blank">
                                            <img src={news} />
                                        </IconButton> */}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={3} xs={12} className={classes.hiddenMobile}>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item md={6} xs={12}>
                                    <List component="nav" className={classes.list}
                                        subheader={
                                            <Typography component={Box} className={classes.title}>
                                                Institucional
                                            </Typography>
                                        }
                                    >
                                        <ListItem button component={RouterLink} to="/sobre-a-avell" className={classes.listItem}>
                                            <ListItemText primary="Sobre a Avell" className={classes.textItem} />
                                        </ListItem>
                                        {/* <ListItem button component={RouterLink} to="/seja-revendedor" className={classes.listItem}>
                                            <ListItemText primary="Seja Revendedor" className={classes.textItem} />
                                        </ListItem> */}
                                        <ListItem
                                            button
                                            component={RouterLink}
                                            to={{ pathname: '/sobre-a-avell', state: { scrollToShops: true } }}
                                            className={classes.listItem}
                                        >
                                            <ListItemText primary="Nossas Lojas" className={classes.textItem} />
                                        </ListItem>
                                        <ListItem button component={RouterLink} to="/politica-de-privacidade" className={classes.listItem}>
                                            <ListItemText primary="Privacidade e Segurança" className={classes.textItem} />
                                        </ListItem>
                                        <ListItem button component={RouterLink} to="/termos-e-condicoes" className={classes.listItem}>
                                            <ListItemText primary="Termos e Condições" className={classes.textItem} />
                                        </ListItem>
                                        <ListItem button component={RouterLink} to="/perguntas-frequentes" className={classes.listItem}>
                                            <ListItemText primary="Perguntas Frequentes" className={classes.textItem} />
                                        </ListItem>
                                        <ListItem button component={RouterLink} to="/politica-de-garantia" className={classes.listItem}>
                                            <ListItemText primary="Política de Garantia" className={classes.textItem} />
                                        </ListItem>
                                        <ListItem button component="a" target="_blank" href="https://avell.thinkcircular.com.br" className={classes.listItem}>
                                            <ListItemText primary="Logística Reversa Avell" className={classes.textItem} />
                                        </ListItem>

                                    </List>
                                    <List component="nav" className={classes.list}
                                        subheader={
                                            <Typography component={Box} className={classes.title}>
                                                Precisa de Ajuda?
                                            </Typography>
                                        }
                                    >
                                        <ListItem button component={RouterLink} to="/central-de-vendas" className={classes.listItem}>
                                            <ListItemText primary="Fale com um consultor" className={classes.textItem} />
                                        </ListItem>
                                        <ListItem button component={RouterLink} to="/suporte-tecnico" className={classes.listItem}>
                                            <ListItemText primary="Central de suporte" className={classes.textItem} />
                                        </ListItem>
                                        <ListItem button component={RouterLink} to="/central-de-vendas" className={classes.listItem}>
                                            <ListItemText primary="Central de vendas" className={classes.textItem} />
                                        </ListItem>
                                        <ListItem button component={RouterLink} to="/regulamento" className={classes.listItem}>
                                            <ListItemText primary="Regulamento" className={classes.textItem} />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item md={6} xs={12}>
                                    <List
                                        component="nav" className={classes.list + ' ' + classes.hiddenMobile}
                                        aria-labelledby="nested-list-subheader"
                                        subheader={
                                            <Typography component={Box} className={classes.title}>
                                                Vendas Corporativas
                                            </Typography>
                                        }
                                    >
                                        <ListItem className={classes.listItem}>
                                            <ListItemText className={classes.textItem} ><strong>Joinville/SC: </strong>(47) 3801-6000</ListItemText>
                                        </ListItem>
                                    </List>
                                    <List component="nav" className={classes.list}
                                        subheader={
                                            <Typography component={Box} className={classes.title + ' ' + classes.hiddenMobile}>
                                                Formas de Pagamento
                                            </Typography>
                                        }
                                    >
                                        <RouterLink to="/formasdepagamento" className={classes.paymentForms}>
                                            <Box className={classes.paymentForm}>
                                                <img src={pagamentoBoletoIcon} />
                                            </Box>
                                            <Box className={classes.paymentForm}>
                                                <img src={pagamentoCartaoIcon} />
                                            </Box>
                                            <Box className={classes.paymentForm}>
                                                <img src={pagamentoSantanderIcon} />
                                            </Box>
                                            <Box className={classes.paymentForm}>
                                                <img src={pagamentoPagseguroIcon} style={{ transform: 'scale(2)', transformOrigin: 'left' }} />
                                            </Box>
                                            <Box className={classes.paymentForm}>
                                                <img src={pagamentoPixIcon} />
                                            </Box>
                                            <Box className={classes.paymentForm}>
                                                <img src={pagamentoNubankIcon} style={{ transform: 'scale(1.5)', transformOrigin: 'left' }} />
                                            </Box>
                                            <Box className={classes.paymentForm}>
                                                <img src={pagamentoAmeIcon} style={{ transform: 'scale(2.5)', transformOrigin: 'left' }} />
                                            </Box>
                                        </RouterLink>
                                    </List>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={3} xs={12} className={classes.hiddenMobile}>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item md={6} xs={12}>
                                    <List
                                        component="nav" className={classes.list}
                                        aria-labelledby="nested-list-subheader"
                                        subheader={
                                            <Typography component={Box} className={classes.title}>
                                                Horários de Atendimento
                                            </Typography>
                                        }
                                    >
                                        <Box mt={1}>
                                            <ListItem className={classes.listItem}>
                                                <ListItemText className={classes.textItem}><strong>Atendimento de Vendas</strong>:</ListItemText>
                                            </ListItem>
                                            <ListItem className={classes.listItem}>
                                                <ListItemText className={classes.textItem}>Segunda a sexta-feira das 09h às 18h30</ListItemText>
                                            </ListItem>
                                            <ListItem className={classes.listItem}>
                                                <ListItemText className={classes.textItem}>Sábado das 09h às 12h30</ListItemText>
                                            </ListItem>
                                        </Box>
                                        <Box mt={1}>
                                            <ListItem className={classes.listItem}>
                                                <ListItemText className={classes.textItem}><strong>Atendimento do Suporte</strong>:</ListItemText>
                                            </ListItem>
                                            <ListItem className={classes.listItem}>
                                                <ListItemText className={classes.textItem}>Segunda a sexta-feira das 08h às 17h45</ListItemText>
                                            </ListItem>
                                        </Box>
                                    </List>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box className={classes.infoDefault} pt={2} pb={2}>
                <Grid container>
                    <Grid item md={7} xs={12} className={classes.hiddenDesktop}>
                        <Typography variant="caption" className={classes.textInfo}>
                            <strong className={classes.textItemBold}>Avell Notebooks de Alto desempenho</strong><br />
                            CNPJ: 19.117.785/0001-05
                        </Typography>
                        <Typography variant="caption" className={classes.textInfo}>
                            Rua Matrinxã, 687, Edifício 3 - Parte 1
                            Distrito Industrial - Manaus - AM<br />
                            CEP: 69.075-150
                        </Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Box>
                            <Typography className={classes.text} variant="caption">
                                Preços e condições podem sofrer alterações sem prévia notificação. Em caso de preços divergentes, considere válido o preço do carrinho de compras.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box className={classes.floatingBar + ' hide-on-print'}>
                {/* {store.social.facebook && (
                    <IconButton className={clsx(classes.floatingBarIcon, classes.floatingBarIconFacebook)} component={Link} href={store.social.facebook} target="blank">
                        <FaceIcon />
                    </IconButton>
                )}

                {store.social.instagram && (
                    <IconButton className={clsx(classes.floatingBarIcon, classes.floatingBarIconInstagram)} component={Link} href={store.social.instagram} target="blank">
                        <InstaIcon />
                    </IconButton>
                )}

                {store.social.youtube && (
                    <IconButton className={clsx(classes.floatingBarIcon, classes.floatingBarIconYoutube)} component={Link} href={store.social.youtube} target="blank">
                        <YoutubeIcon />
                    </IconButton>
                )} */}

                {/* <IconButton
                    className={clsx(classes.floatingBarIcon, classes.floatingBarIconVendas)}
                    component={Link}
                    onClick={() => { history.push('/central-de-vendas') }}
                >
                    <img src={centralDeVendasIcon} style={{ width: '48px' }} />
                </IconButton> */}

                {!mobileSize && linkWhatsapp && (
                    <IconButton className={clsx(classes.floatingBarIcon, classes.floatingBarIconWhatsapp)} component={Link} href={linkWhatsapp} target="blank">
                        <WhatsAppIcon />
                    </IconButton>
                )}

                {!mobileSize && (
                    <IconButton component={Link} onClick={scrollToTop}>
                        <img src={top} style={{ width: '40px', height: '40px' }} />
                    </IconButton>
                )}
            </Box>

            {store.maintenance && (
                <Snackbar
                    open={true}
                >
                    <Alert severity="warning">
                        Loja em manutenção. Nenhuma compra será aceita no momento.
                    </Alert>
                </Snackbar>
            )}

            <ComparisonDialog />

            {dialogGlobal == 'dialog-vendas' ?
                <DialogVendas
                    open={true}
                    title=''
                    handleClose={() => { dispatch(closeDialogGlobal()) }}
                /> : null
            }

            {dialogGlobal == 'dialog-0800' ?
                <DialogTelefoneSuporte
                    open={true}
                    title=''
                    handleClose={() => { dispatch(closeDialogGlobal()) }}
                /> : null
            }

            <EmutuaFooter />
        </>
    );
}

Footer.propTypes = {
    className: PropTypes.string
};

export default Footer;
